/**
 * Set of possible page types by authorization status.
 */
const PageTypesByAuthorizationStatus = {
  /**
   * Public page.
   */
  Public: "Public",

  /**
   * Page, accessible only for authorized users.
   */
  Authorized: "Authorized",

  /**
   * Page, accessible only for non-authorized users.
   */
  NotAuthorized: "Not authorized",
};

/**
 * Session cookie name.
 */
const SESSION_COOKIE_NAME = "aft.sid";

module.exports = {
  PageTypesByAuthorizationStatus,
  SESSION_COOKIE_NAME,
};
