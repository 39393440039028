import { useEffect } from "react";

import { useRouter } from "next/router";
import { useSelector } from "react-redux";

import { selectIsSignedIn } from "@aft/client-modules/authorization/selectors";
import { clientRoutesManager } from "@aft/client-routes";
import { PageTypesByAuthorizationStatus } from "@aft/constants/general/authorization";

export { PageTypesByAuthorizationStatus };

/**
 * Hook to watch over the user authorization state and redirect user to another page
 * in a case he don't have relative status.
 *
 * @param pageTypeByAuthorizationStatus - Page type by authorization status.
 */
export const useAuthorizationStateWatcher = (pageTypeByAuthorizationStatus) => {
  const router = useRouter();

  const isSignedIn = useSelector(selectIsSignedIn);

  useEffect(() => {
    switch (pageTypeByAuthorizationStatus) {
      case PageTypesByAuthorizationStatus.Authorized:
        if (
          !isSignedIn &&
          router.pathname !== clientRoutesManager.rootNotAuthorizedRoute.pathname
        ) {
          router.replace(clientRoutesManager.rootNotAuthorizedRoute.pathname);
        }

        break;
      case PageTypesByAuthorizationStatus.NotAuthorized:
        if (isSignedIn && router.pathname !== clientRoutesManager.rootAuthorizedRoute.pathname) {
          router.replace(clientRoutesManager.rootAuthorizedRoute.pathname);
        }

        break;
      case PageTypesByAuthorizationStatus.Public:
      default:
        return;
    }
  }, [router, isSignedIn, pageTypeByAuthorizationStatus]);
};
